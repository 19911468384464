import { Component, OnInit,Inject } from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlquraService } from '../../services/alqura.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-alqura-edit',
  templateUrl: './alqura-edit.component.html',
  styleUrls: ['./alqura-edit.component.css']
})
export class AlquraEditComponent implements OnInit {

   Q_Arabic_Name:string;
   Q_English_Name:string;
  q_order:number;
  qary:any;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alquraService:AlquraService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {this.getQary();
  }

  getQary(){
    let q_id = window.localStorage.getItem("QeditQary_Id");
    if(!q_id) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['alqura']);
    return;}
    else{ 
    this._spinner.show();
      this._alquraService.getAlqaryById(q_id)
      .subscribe((resp:{})=>{this.resp=resp
        if(!this.resp.error&& this.resp.count>0){
          this.qary=this.resp.alqary;
          this. Q_Arabic_Name=this.qary.Q_Arabic_Name;
          this. Q_English_Name=this.qary.Q_English_Name;
          this.q_order=this.qary.Q_Order;
          this._spinner.hide();
        }
      });
    }
  }
  onEditQary(){
    if(!this. Q_Arabic_Name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالعربية مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this. Q_English_Name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالإنجليزية مطلوبة"],
      dismissible: true, 
      timeout: false,
      type: 'danger' });
      return false;
      }
      this._spinner.show();
    const qaryUp={
      Q_Arabic_Name:this. Q_Arabic_Name,
      Q_English_Name:this. Q_English_Name,
      Q_Order:this.q_order}
    this._alquraService.editAlqary(this.qary._id,qaryUp).subscribe(
      (resp:{})=>{this.resp=resp
      if(this.resp.error|| this.resp.num===0){
        this._spinner.hide();
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(this.resp.error || this.resp.num===2){
        this._flashMsg.showFlashMessage(
          { messages: ['هذا الاسم موجود من قبل'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(!this.resp.error || this.resp.num===1){
       this._flashMsg.showFlashMessage(
        { messages: ['تم تعديل بيانات القاريء'],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
         window.localStorage.removeItem("QeditQary_Id");
        return this._router.navigate(['/alqura']); }
      }
    );
  }
}
