import { Component, OnInit,Inject } from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlquraService } from '../../services/alqura.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-alqura-add',
  templateUrl: './alqura-add.component.html',
  styleUrls: ['./alqura-add.component.css']
})
export class AlquraAddComponent implements OnInit {

  ar_name:string;
  en_name:string;
  q_order:number;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alquraService:AlquraService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
  } 

  onCreateQary(){
    if(!this.ar_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالعربية مطلوب"],
      dismissible: true, 
      timeout: false,
      type: 'danger' });
      return false;
      }
      if(!this.en_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالإنجليزية مطلوبة"],
      dismissible: true, 
      timeout: false,
      type: 'danger' });
      return false;
      }
      this._spinner.show();
    const qary={
      Q_Arabic_Name:this.ar_name,
      Q_English_Name:this.en_name,
      Q_Order:this.q_order,
      Inserted_By :this._alquraService.getCurrentUserID()}
    this._alquraService.createAlqary(qary).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
      if(this.resp.error || this.resp.num===2){
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
          dismissible: true, 
          timeout: false,
          type: 'danger' });
      }
      if(this.resp.error || this.resp.num===2){
        this._flashMsg.showFlashMessage(
          { messages: ['هذا الاسم موجود من قبل'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(!this.resp.error || this.resp.num===1){
        this._flashMsg.showFlashMessage(
          { messages: ['تم إضافة بيانات القاريء'],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
      return this._router.navigate(['/alqura']); 
      }}
      );
  }

}
