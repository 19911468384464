import { Component, OnInit ,Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlquraService } from '../../services/alqura.service';
import { NgxSpinnerService } from "ngx-spinner"; 


@Component({
  selector: 'app-alqura',
  templateUrl: './alqura.component.html',
  styleUrls: ['./alqura.component.css']
})
export class AlquraComponent implements OnInit {
  term:string;
  config:any;
  alqura:any;
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
  private _alquraService:AlquraService,
  private _route: ActivatedRoute, 
 private _router:Router,
 private _flashMsg:NgFlashMessageService) { }

ngOnInit() {
 this.getAlqura();
 this.config = {
   currentPage: 1,
   itemsPerPage: 6,
   totalItems:0
   };    
   this._route.queryParams.subscribe(
     params => this.config.currentPage= params['page']?params['page']:1 );      
}
pageChange(newPage: number) {
   this._router.navigate(['alqura'], { queryParams: { page: newPage } });
 }
 getAlqura(){
   this._spinner.show();
   this._alquraService.getAlqura().subscribe(
   (resp:{})=>{this.resp=resp
       this._spinner.hide();  
     if(!this.resp.error&& this.resp.count>0){
       this.alqura=this.resp.alqura;
   }});
}
EditQary(q_Id){
 window.localStorage.removeItem("QeditQary_Id");
     window.localStorage.setItem("QeditQary_Id", q_Id.toString());
     this._router.navigate(['alqura-edit']);
}
delQary(q_Id){
  this._spinner.show();
 this._alquraService.delAlqary(q_Id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(this.resp.error){
       this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
         dismissible: true, 
         timeout: 5000,
         type: 'danger' });
     }
       this._flashMsg.showFlashMessage(
        { messages: ['تم حذف بيانات القاريء'],
       dismissible: true, 
       timeout: 5000,
       type: 'success' });
       this.getAlqura();}
     );}
}
