import { Component, OnInit } from '@angular/core';
import{UsersService}from'../../services/users.service';
import{Router}from'@angular/router';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  constructor(
    private _usersService:UsersService,
   private _router:Router) { }

  ngOnInit(): void {
  }

  onLogoutClick(){
    this._usersService.logout();
    this._router.navigate(['/login']);
    return false;
  }
}
