import { Component, OnInit,Inject } from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {

  U_Name:string;
  U_Mail:string;
  U_Password:string;
  C_Password:string;
  U_Type:number;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _usersService:UsersService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
  }
  onCreateUser(){
    if(!this.U_Name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً اسم المستخدم مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.U_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمة المرور مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.C_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً يجب تأكيد كلمة المرور"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(this.U_Password!==this.C_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمتا المرور غير متطابقتان"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    const user={
      U_Name:this.U_Name,
      U_Mail:this.U_Mail,
      U_Password:this.U_Password,
      U_Type:this.U_Type,}
      this._spinner.show();
    this._usersService.createUser(user).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
        if(this.resp.error || this.resp.num==0){
          this._flashMsg.showFlashMessage(
            { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
            dismissible: true, 
            timeout: 5000,
            type: 'danger' });
        }
        if(this.resp.error || this.resp.num==2){
          this._flashMsg.showFlashMessage(
            { messages: ['عفواً هذا الاسم موجود'],
            dismissible: true, 
            timeout: 5000,
            type: 'danger' });
      }
      if(!this.resp.error && this.resp.num==1){
        this._flashMsg.showFlashMessage(
          { messages: ['تم إضافة بيانات المستخدم '],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
      return this._router.navigate(['/users']);} }
      );
  }

}
