import { Component, OnInit,Inject } from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import{NgxSpinnerService}from'ngx-spinner';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  U_Mail:string;
  U_Name:string;
  N_Password:string;
  CN_Password:string;
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _usersService:UsersService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
  }

  onEdit(){
    if(!this.U_Mail ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً البريد الإلكتروني مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.U_Name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً اسم المستخدم مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.N_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمة المرور الجديدة مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.CN_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً يجب تأكيد كلمة المرور الجديدة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(this.N_Password!==this.CN_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمتا المرور غير متطابقتان"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    const user={
      U_Mail:this.U_Mail,
      U_Name:this.U_Name,
      New_U_Password:this.N_Password}
      this._spinner.show();
    this._usersService.editUserPass(user).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
      if(this.resp.error ){
        this._flashMsg.showFlashMessage(
          { messages: ["عفواً لم يتم تغيير كلمة المرور"],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
       this._flashMsg.showFlashMessage(
        { messages: ["تم تغيير كلمة المرور بنجاح"],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
        return this._router.navigate(['/login']); 
      }
    );
  }
}
