import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { SouarAlquraService } from '../../services/souar-alqura.service';
import { AlquraService } from '../../services/alqura.service';
import { AlsouarService } from '../../services/alsouar.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-souar-alqura-edit',
  templateUrl: './souar-alqura-edit.component.html',
  styleUrls: ['./souar-alqura-edit.component.css']
})
export class SouarAlquraEditComponent implements OnInit {
  ar_name:string;
  q_id:string;
  s_id:string;
  a_link:string;
  v_link:string;
  alqary:any;
  alsouar:any;
  alsoura:any;
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _souarAlquraService:SouarAlquraService,
    private _alquraService: AlquraService, 
    private _alsouarService: AlsouarService, 
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
    this.getAlsouar();
    this.getAlsoura();
  }
  getAlsoura(){
    let qary_id = window.localStorage.getItem("SQSeditSQary_Id");
    let soura_id=window.localStorage.getItem("SQSeditSSoura_Id");
  if(!qary_id ) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['souarAlqura-show']);
    return;}
    if(!soura_id ) {
      alert("عفواً حدث خطأ") 
      this._router.navigate(['souarAlqura-show']);
      return;}
    else{ 
      this._spinner.show();
    this._souarAlquraService.getSouarAlquraOne(qary_id,soura_id).subscribe(
    (resp:{})=>{this.resp=resp
      this._spinner.hide();
      if(!this.resp.error  && this.resp.count>0){
        this.alsoura=this.resp.alsoura;
        this.q_id=this.alsoura.Q_ID._id;
        this.s_id=this.alsoura.S_ID._id;
        this.ar_name=this.alsoura.Q_ID.Q_Arabic_Name;
        console.log(this.ar_name)
        if(this.alsoura.Audio_Link!=="null" && this.alsoura.Audio_Link!=="undefined"){
            this.a_link=this.alsoura.Audio_Link;
        }
        if(this.alsoura.Video_Link!=="null"&& this.alsoura.Video_Link!=="undefined"){
          this.v_link=this.alsoura.Video_Link;}
    }});}
 }
 getAlsouar(){
 this ._alsouarService.getAlsouar().subscribe(
   (resp:{})=>{this.resp=resp
     if(!this.resp.error && this.resp.count>0){
       this.alsouar=this.resp.alsouar;
   }});
}
onEditSQ(){
  if(this.q_id ==null){
    this._flashMsg.showFlashMessage(
    { messages: ["عفواً لم يتم إختيار القاريء"],
    dismissible: true, 
    timeout: 5000,
    type: 'danger' });
    return false;
    }
    if(!this.s_id ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً لم يتم إختيار السورة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    if(!this.a_link && !this.v_link ){
    this._flashMsg.showFlashMessage(
    { messages: ["عفواً يجب إضافة رابط واحد على الأقل"],
    dismissible: true, 
    timeout: 5000,
    type: 'danger' });
    return false;
    }
  const souar_alqary={
    Q_ID:this.q_id,
    S_ID:this.s_id,
    Audio_Link:this.a_link,
    Video_Link:this.v_link
  }
  this._souarAlquraService.editSouarAlqura(souar_alqary).subscribe(
    (resp:{})=>{this.resp=resp
    if(this.resp.error|| this.resp.num===0){
      this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
    }
    if(this.resp.error || this.resp.num===2){
      this._flashMsg.showFlashMessage(
        { messages: ['تم إضافة السورة للقاريء من قبل'],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
    }
    if(!this.resp.error || this.resp.num===1){
     this._flashMsg.showFlashMessage(
      { messages: ['تم تعديل بيانات السورة '],
      dismissible: true, 
      timeout: 5000,
      type: 'success' });
       window.localStorage.removeItem("SQSeditSQary_Id");
       window.localStorage.removeItem("SQSeditSSoura_Id");
      return this._router.navigate(['souarAlqura-show']); }
    }
  );

}

}