import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import  { map } from 'rxjs/operators';
import  * as AppUtils  from '../common/app.util';

@Injectable({
  providedIn: 'root'
})
export class SouarAlquraService {

  constructor(private _http:HttpClient) { }
  private createAuthHeader(headers:HttpHeaders){
    var token=localStorage.getItem(AppUtils.AUTH_TOKEN);
    headers = headers.set('Authorization',`Bearer ${token} `);
    return headers;
  }
  public getCurrentUserID(){
    var user= JSON.parse(localStorage.getItem(AppUtils.USER_INFO));
    return user.U_ID;
  }
  public createSouarAlqura(souarAlqura){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.post("souar_alqura/add",souarAlqura,header)
    .pipe(map(resp=>resp));  
  }
  public getSouarAlqura(){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("souar_alqura",header)
    .pipe(map(resp=>resp)); 
  }
  public getSouarAlquraAll(){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("souar_alqura/all",header)
    .pipe(map(resp=>resp)); 
  }
  public getSouarAlquraByQaryID(q_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("souar_alqura/getByQary/"+q_Id,header)
    .pipe(map(resp=>resp)); 
  }
  public getSouarAlquraOne(q_Id,s_id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("souar_alqura/getOne/"+q_Id+"&"+s_id,header)
    .pipe(map(resp=>resp)); 
  }

  public editSouarAlqura(souarAlqura){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.put("souar_alqura/edit",souarAlqura,header)
    .pipe(map(resp=>resp));  
  }
  public delSouarAlquraByQ(q_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.delete("souar_alqura/deleteAll/"+q_Id,header)
    .pipe(map(resp=>resp)); 
  }
  public delSouarAlquraOne(q_Id,s_id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.delete("souar_alqura/"+q_Id+"&"+s_id,header)
    .pipe(map(resp=>resp)); 
  }
}
