import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import  { map } from 'rxjs/operators';
import  * as AppUtils  from '../common/app.util';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
    constructor(private _http:HttpClient) { }
   
  public login(user){
    const headers=new HttpHeaders();
    const header={headers:headers.set('Content-Type', 'application/json; charset=utf-8')};
    return this._http.post("users/login",user,header)
    .pipe(map(resp=>resp));  
  } 

  private createAuthHeader(headers:HttpHeaders){
    var token=localStorage.getItem(AppUtils.AUTH_TOKEN);
    headers = headers.set('Authorization',`Bearer ${token} `);
    headers= headers.set('Content-Type', 'application/json; charset=utf-8');
    return headers;
  } public createUser(user){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.post("users/add",user,header)
    .pipe(map(resp=>resp));  
  }
  public getUsers(){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    console.log(this._http.get("users/",header)
    .pipe(map(resp=>resp)))
    return this._http.get("users/",header)
    .pipe(map(resp=>resp)); 
  }
  public getUserFavorites(u_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("users_favorites/getByUser/"+u_Id,header)
    .pipe(map(resp=>resp)); 
  }
  public delUserFavorites(u_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.delete("users_favorites/"+u_Id,header)
    .pipe(map(resp=>resp)); 
  }
  public getUserById(u_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("users/"+u_Id,header)
    .pipe(map(resp=>resp));
  }
  
  public editUser(u_Id,user){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.put("users/edit/"+u_Id,user,header)
    .pipe(map(resp=>resp));  
  }
  public editUserPass(user){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.put("users/passReset/",user,header)
    .pipe(map(resp=>resp));  
  }
  public editRecycle(user){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.put("users/recycle/",user,header)
    .pipe(map(resp=>resp));  
  }
  public delUser(u_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.delete("users/"+u_Id,header)
    .pipe(map(resp=>resp)); 
  }
  public saveUserData(token,user){
    localStorage.setItem(AppUtils.AUTH_TOKEN,token);
    localStorage.setItem(AppUtils.USER_INFO,JSON.stringify(user));
  }
  public isAdmin():boolean{
    if(localStorage.getItem(AppUtils.AUTH_TOKEN)){
    var user= JSON.parse(localStorage.getItem(AppUtils.USER_INFO));
    if(user.U_Type===true){
      return true;
    }}
    else return false;
  }
  public isLoggedIn():boolean{
    return !!localStorage.getItem(AppUtils.AUTH_TOKEN);
  }
  public getCurrentUser(){
    return JSON.parse(localStorage.getItem(AppUtils.USER_INFO));
  }
  public getUserID(){
    var user= JSON.parse(localStorage.getItem(AppUtils.USER_INFO));
    return user.U_ID;
  }
  public logout(){
    localStorage.removeItem(AppUtils.AUTH_TOKEN);
    localStorage.removeItem(AppUtils.USER_INFO);
  }
}
