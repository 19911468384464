import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  term: string;
  config:any;
  users:any[]; 
  order: string = 'U_Name';
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
  private _usersService:UsersService,
  private _route: ActivatedRoute, 
 private _router:Router,
 private _flashMsg:NgFlashMessageService) {}

ngOnInit() {
 this.getUsers(); 
 this.config = {
   currentPage: 1,
   itemsPerPage: 6,
   totalItems:0
   };    
   this._route.queryParams.subscribe(
     params => this.config.currentPage= params['page']?params['page']:1 );      
}
pageChange(newPage: number) {
   this._router.navigate(['users'], { queryParams: { page: newPage } });
 }
getUsers(){
  this._spinner.show();
 this ._usersService.getUsers().subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(!this.resp.error && this.resp.count>0){
       this.users=this.resp.users;
   }});
}
Favorites(u_id){
  window.localStorage.removeItem("QFUser_Id");
  window.localStorage.setItem("QFUser_Id", u_id.toString());
  this._router.navigate(['users-favorites']);
}
EditUser(u_id){
 window.localStorage.removeItem("QeditUser_Id");
     window.localStorage.setItem("QeditUser_Id", u_id.toString());
     this._router.navigate(['users-edit']);
}
delUser(u_Id){
  this._spinner.show();
 this._usersService.delUser(u_Id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(this.resp.error){
       this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
         dismissible: true, 
         timeout: 5000,
         type: 'danger' });
     }
       this._flashMsg.showFlashMessage(
        { messages: ['تم حذف بيانات المستخدم'],
       dismissible: true, 
       timeout: 5000,
       type: 'success' });
       this.getUsers();}
     );
} 
}
