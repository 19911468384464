import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-favorites',
  templateUrl: './users-favorites.component.html',
  styleUrls: ['./users-favorites.component.css']
})
export class UsersFavoritesComponent implements OnInit {
  term:string;
  config:any;
  U_Name:string;
  users_favorites:any;
  souar:any=[];
  qura:any=[];
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _usersService:UsersService,private _route:ActivatedRoute,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {this.getFavorites()
    this.config = {
      currentPage: 1,
      itemsPerPage: 6,
      totalItems:0
      };    
      this._route.queryParams.subscribe(
        params => this.config.currentPage= params['page']?params['page']:1 );      
   }
   pageChange(newPage: number) {  
      this._router.navigate(['users-favorites'], { queryParams: { page: newPage } });
    }
   
  getFavorites(){
    let u_id = window.localStorage.getItem("QFUser_Id");
    if(!u_id) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['users']);
    return;}
    else{ 
      this._spinner.show();
      this._usersService.getUserFavorites(u_id)
      .subscribe((resp:{})=>{this.resp=resp
        this._spinner.hide();
        if(!this.resp.error && this.resp.count>0){
          this.users_favorites=this.resp.users_favorites;
          this.U_Name=this.users_favorites[0].U_ID.U_Name;
          console.log(this.users_favorites)
        }
      });
    }
  }
  delUserFav(u_Id){
    this._spinner.show();
    this._usersService.delUserFavorites(u_Id).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
        if(this.resp.error){
          this._flashMsg.showFlashMessage(
            { messages: [this.resp.message],
            dismissible: true, 
            timeout: 5000,
            type: 'danger' });
        }
          this._flashMsg.showFlashMessage(
          { messages: [this.resp.message],
          dismissible: true, 
          timeout: 5000,
          type: 'success' });
          this.getFavorites();}
        );
  }
}
