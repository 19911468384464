import { Component, OnInit ,Inject} from '@angular/core';
import { Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { SouarAlquraService } from '../../services/souar-alqura.service';
import { AlquraService } from '../../services/alqura.service';
import { AlsouarService } from '../../services/alsouar.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-souar-alqura-add',
  templateUrl: './souar-alqura-add.component.html',
  styleUrls: ['./souar-alqura-add.component.css']
})
export class SouarAlquraAddComponent implements OnInit {
   Q_Arabic_Name:string;
  q_id:String;
  s_id:String;
  a_link:string;
  v_link:string;
  alsouar:any;
  alqary:any;
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _souarAlquraService:SouarAlquraService,
    private _alquraService: AlquraService, 
    private _alsouarService: AlsouarService, 
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {this.getAlqura();this.getAlsouar();
  }

  getAlqura(){
    let qary_id = window.localStorage.getItem("SQSAddSQary_Id");
  if(!qary_id ) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['souarAlqura-show']);
    return;}
    else{
      this._spinner.show();
    this._alquraService.getAlqaryById(qary_id).subscribe(
    (resp:{})=>{this.resp=resp
      this._spinner.hide();
      if(!this.resp.error && this.resp.count>0){
        this.alqary=this.resp.alqary;
        this.q_id=this.alqary._id;
        this. Q_Arabic_Name=this.alqary.Q_Arabic_Name;
    }});}
 }
 getAlsouar(){
   this._spinner.show();
 this ._alsouarService.getAlsouar().subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(!this.resp.error){
       this.alsouar=this.resp.alsouar;
   }});
}
onCreateSQ(){  
  if(this.q_id ===null){
    this._flashMsg.showFlashMessage(
    { messages: ["عفواً لم يتم إختيار القاريء"],
    dismissible: true, 
    timeout: 5000,
    type: 'danger' });
    return false;
    }
    if(!this.s_id ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً لم يتم إختيار السورة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    if(!this.a_link && !this.v_link ){
    this._flashMsg.showFlashMessage(
    { messages: ["عفواً يجب إضافة رابط واحد على الأقل"],
    dismissible: true, 
    timeout: 5000,
    type: 'danger' });
    return false;
    }
  const souar_alqary={
    Q_ID:this.q_id,
    S_ID:this.s_id,
    Audio_Link:this.a_link,
    Video_Link:this.v_link
  }
    this._spinner.show();
  this._souarAlquraService.createSouarAlqura(souar_alqary).subscribe(
    (resp:{})=>{this.resp=resp
      this._spinner.hide();
    if(this.resp.error|| this.resp.num===0){
      this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
    }
    if(this.resp.error || this.resp.num===2){
      this._flashMsg.showFlashMessage(
        { messages: ['تم إضافة السورة للقاريء من قبل'],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
    }
    if(!this.resp.error || this.resp.num===1){
      this._flashMsg.showFlashMessage(
        { messages: ['تم إضافة السورة'],
      dismissible: true, 
      timeout: 5000,
      type: 'success' });
    return this._router.navigate(['/souarAlqura-show']); }}
    );
}
}
