import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule,Routes } from '@angular/router';
import {HashLocationStrategy ,LocationStrategy,DatePipe} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import{BidiModule}from '@angular/cdk/bidi';
import { FormsModule } from '@angular/forms';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSpinnerModule } from "ngx-spinner"; 
import { UsersService } from './services/users.service';

import { AuthGuard } from './guards/auth.guard';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { UsersAddComponent } from './components/users-add/users-add.component';
import { UsersEditComponent } from './components/users-edit/users-edit.component';
import { AlquraComponent } from './components/alqura/alqura.component';
import { AlquraAddComponent } from './components/alqura-add/alqura-add.component';
import { AlquraEditComponent } from './components/alqura-edit/alqura-edit.component';
import { AlsouarComponent } from './components/alsouar/alsouar.component';
import { AlsouarAddComponent } from './components/alsouar-add/alsouar-add.component';
import { AlsouarEditComponent } from './components/alsouar-edit/alsouar-edit.component';
import { SouarAlquraComponent } from './components/souar-alqura/souar-alqura.component';
import { SouarAlquraAddComponent } from './components/souar-alqura-add/souar-alqura-add.component';
import { SouarAlquraEditComponent } from './components/souar-alqura-edit/souar-alqura-edit.component';
import { SouarAlquraShowComponent } from './components/souar-alqura-show/souar-alqura-show.component';
import { UsersFavoritesComponent } from './components/users-favorites/users-favorites.component';
import { RegisterComponent } from './components/register/register.component';

//import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';


const AppRoutes:Routes=[
  {path:'',component:AlsouarComponent,canActivate:[AuthGuard]},
  {path:'login',component:LoginComponent},
  {path:'passwordReset',component:PasswordResetComponent},
  {path:'users',component:UsersComponent,canActivate:[AuthGuard]},
  {path:'users-add',component:UsersAddComponent,canActivate:[AuthGuard]},
  {path:'users-edit',component:UsersEditComponent,canActivate:[AuthGuard]},
  {path:'alqura',component:AlquraComponent,canActivate:[AuthGuard]},
  {path:'alqura-add',component:AlquraAddComponent,canActivate:[AuthGuard]},
  {path:'alqura-edit',component:AlquraEditComponent,canActivate:[AuthGuard]},
  {path:'alsouar',component:AlsouarComponent,canActivate:[AuthGuard]},
  {path:'alsouar-add',component:AlsouarAddComponent,canActivate:[AuthGuard]},
  {path:'alsouar-edit',component:AlsouarEditComponent,canActivate:[AuthGuard]},
  {path:'souarAlqura',component:SouarAlquraComponent,canActivate:[AuthGuard]},
  {path:'souarAlqura-add',component:SouarAlquraAddComponent,canActivate:[AuthGuard]},
  {path:'souarAlqura-edit',component:SouarAlquraEditComponent,canActivate:[AuthGuard]},
  {path:'souarAlqura-show',component:SouarAlquraShowComponent,canActivate:[AuthGuard]},
  {path:'users-favorites',component:UsersFavoritesComponent,canActivate:[AuthGuard]}
]
@NgModule({
  declarations: [
    AppComponent,NavbarComponent,SidenavComponent,HomeComponent,FooterComponent,
    LoginComponent,PasswordResetComponent,
    UsersComponent,UsersAddComponent,UsersEditComponent,
    AlquraComponent,AlquraAddComponent,AlquraEditComponent,   
    AlsouarComponent,AlsouarAddComponent,AlsouarEditComponent,
    SouarAlquraComponent,SouarAlquraAddComponent,SouarAlquraEditComponent,
    SouarAlquraShowComponent,UsersFavoritesComponent,RegisterComponent
  ],
  imports: [
    CommonModule,FileUploadModule,BrowserModule,AppRoutingModule,HttpClientModule,BrowserAnimationsModule,FormsModule,BidiModule,
    NgFlashMessagesModule,NgxPaginationModule,Ng2SearchPipeModule,NgxSpinnerModule,
    RouterModule.forRoot(AppRoutes),
    //ToastrModule.forRoot()
  ],
  providers: [
    {provide: LocationStrategy,useClass:HashLocationStrategy} ,DatePipe,
    UsersService,
    AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
