import { Component, OnInit ,Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlsouarService } from '../../services/alsouar.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-alsouar',
  templateUrl: './alsouar.component.html',
  styleUrls: ['./alsouar.component.css']
})
export class AlsouarComponent implements OnInit {
  term:string;
  config:any;
  alsouar:any;
  resp:any=[];

  constructor(
  @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alsouarService:AlsouarService,
    private _route: ActivatedRoute, 
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
 this.getAlsouar();
 this.config = {
   currentPage: 1,
   itemsPerPage: 6,
   totalItems:0
   };    
   this._route.queryParams.subscribe(
     params => this.config.currentPage= params['page']?params['page']:1 );      
}
pageChange(newPage: number) {
   this._router.navigate(['alsouar'], { queryParams: { page: newPage } });
 }
 getAlsouar(){
   this._spinner.show();
 this ._alsouarService.getAlsouar().subscribe(
   (resp:{})=>{this.resp=resp
       this._spinner.hide();
     if(!this.resp.error){
       this.alsouar=this.resp.alsouar;
   }});
}
EditSouara(s_Id){
 window.localStorage.removeItem("QeditSoura_Id");
     window.localStorage.setItem("QeditSoura_Id", s_Id.toString());
     this._router.navigate(['alsouar-edit']);
}
delSouara(s_Id){
  this._spinner.show();
 this._alsouarService.delAlsouara(s_Id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(this.resp.error&& this.resp.count>0){
       this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
         dismissible: true, 
         timeout: 5000,
         type: 'danger' });
     }
       this._flashMsg.showFlashMessage(
        { messages: ['تم حذف بيانات السورة'],
       dismissible: true, 
       timeout: 5000,
       type: 'success' });
       this.getAlsouar();}
     );}
}
