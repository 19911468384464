import { Component, OnInit ,Inject} from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {
  U_Name:string;
  U_Mail:string;
  U_Password:string;
  C_Password:string;
  U_Type:boolean;
  user:any;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _usersService:UsersService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {this.getUser();}
  getUser(){
    let u_id = window.localStorage.getItem("QeditUser_Id");
    if(!u_id) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['users']);
    return;}
    else{ this._spinner.show();
      this._usersService.getUserById(u_id)
      .subscribe((resp:{})=>{this.resp=resp
        this._spinner.hide();
        if(!this.resp.error && this.resp.count>0){
          this.user=this.resp.user;
          this.U_Name=this.user.U_Name;
          this.U_Mail=this.user.U_Mail;
          this.U_Password=this.user.U_Password;
          this.C_Password=this.user.U_Password;
          this.U_Type=this.user.U_Type;
        }
      });
    }
  }
  onEditUser(){
    if(!this.U_Name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً اسم المستخدم مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.U_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمة المرور مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.C_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً يجب تأكيد كلمة المرور"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(this.U_Password!==this.C_Password ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً كلمتا المرور غير متطابقتان"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    const userUp={
      U_Name:this.U_Name,
      U_Mail:this.U_Mail,
      U_Password:this.U_Password,
      U_Type:this.U_Type}
      this._spinner.show();
    this._usersService.editUser(this.user._id,userUp).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
      if(this.resp.error|| this.resp.num==0){
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(this.resp.error && this.resp.num==2){
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً هذا الاسم موجود'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(!this.resp.error && this.resp.num==1){
       this._flashMsg.showFlashMessage(
        { messages: ['تم تعديل بياتات المستخدم '],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
         window.localStorage.removeItem("QeditUser_Id");
        return this._router.navigate(['/users']);} 
      }
    );
  }
}
