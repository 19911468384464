import { Component, OnInit,Inject } from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlsouarService } from '../../services/alsouar.service';
import{NgxSpinnerService}from'ngx-spinner';

@Component({
  selector: 'app-alsouar-edit',
  templateUrl: './alsouar-edit.component.html',
  styleUrls: ['./alsouar-edit.component.css']
})
export class AlsouarEditComponent implements OnInit {

  ar_name:string;
  en_name:string;
  a_count:number;
  m_order:number;
  soura:any;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alsouarService:AlsouarService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {this.getSoura();
  }
  getSoura(){
    let s_id = window.localStorage.getItem("QeditSoura_Id");
    if(!s_id) {
    alert("عفواً حدث خطأ") 
    this._router.navigate(['alsouar']);
    return;}
    else{ 
    this._spinner.show();
      this._alsouarService.getAlsouaraById(s_id)
      .subscribe((resp:{})=>{this.resp=resp
        this._spinner.hide();
        if(!this.resp.error && this.resp.count>0){
          this.soura=this.resp.alsouara;
          this.ar_name=this.soura.S_Arabic_Name;
          this.en_name=this.soura.S_English_Name;
          this.a_count=this.soura.S_Ayat_Count;
          this.m_order=this.soura.Moshaf_Order;
        }
      });
    }
  }
  onEditSoura(){
    if(!this.ar_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالعربية مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.en_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالإنجليزية مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.en_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالإنجليزية مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.a_count ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً عدد الآيات مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.m_order ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً ترتيب مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
    const souraUp={
      S_Arabic_Name:this.ar_name,
      S_English_Name:this.en_name,
      S_Ayat_Count:this.a_count,
      Moshaf_Order:this.m_order}
      this._spinner.show();
    this._alsouarService.editAlsouara(this.soura._id,souraUp).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
      if(this.resp.error || this.resp.num===0){
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(this.resp.error || this.resp.num===2){
        this._flashMsg.showFlashMessage(
          { messages: ['هذا الاسم موجود من قبل'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(!this.resp.error || this.resp.num===1){
       this._flashMsg.showFlashMessage(
        { messages: ['تم تعديل بيانات السورة'],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
         window.localStorage.removeItem("QeditSoura_Id");
        return this._router.navigate(['/alsouar']); }
      }
    );
  }
}
