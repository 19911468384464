import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { SouarAlquraService } from '../../services/souar-alqura.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-souar-alqura-show',
  templateUrl: './souar-alqura-show.component.html',
  styleUrls: ['./souar-alqura-show.component.css']
})
export class SouarAlquraShowComponent implements OnInit {
  term:string;
  config:any;
  souarAlqary:any[] = [];
  Alqary:any;
  resp:any=[];
  Q_Arabic_Name:string;
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
  private _souarAlquraService:SouarAlquraService,
  private _route: ActivatedRoute, 
 private _router:Router,
 private _flashMsg:NgFlashMessageService) { }

ngOnInit() {
 this.getSouarAlqura();
 this.config = {
   currentPage: 1,
   itemsPerPage: 6,
   totalItems:0
   };    
   this._route.queryParams.subscribe(
     params => this.config.currentPage= params['page']?params['page']:1 );      
}
pageChange(newPage: number) {
   this._router.navigate(['souarAlqura-show'], { queryParams: { page: newPage } });
 }
 getSouarAlqura(){   
  let q_id = window.localStorage.getItem("SQShowSQary_Id");
  if(!q_id) {
  alert("عفواً حدث خطأ") 
  this._router.navigate(['souarAlqura']);
  return;}
  else{ this._spinner.show();
      this._souarAlquraService.getSouarAlquraByQaryID(q_id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(!this.resp.error && this.resp.count >0){
       this.souarAlqary=this.resp.souarAlqary;
       this.Q_Arabic_Name=this.souarAlqary[0].Q_ID.Q_Arabic_Name;
       console.log(this.Q_Arabic_Name)
   }});}
}
AddSouarAlqary(q_Id){
  window.localStorage.removeItem("SQSAddSQary_Id");
  window.localStorage.setItem("SQSAddSQary_Id", q_Id);
  this._router.navigate(['souarAlqura-add']);
}
EditSouarAlqary(q_Id,s_Id){
  console.log(q_Id,s_Id)
  window.localStorage.removeItem("SQSeditSQary_Id");
  window.localStorage.setItem("SQSeditSQary_Id", q_Id);
  window.localStorage.removeItem("SQSeditSSoura_Id");
  window.localStorage.setItem("SQSeditSSoura_Id", s_Id);
  this._router.navigate(['souarAlqura-edit']);
}
delSouarAlqura(q_Id,s_Id){
  this._spinner.show();
 this._souarAlquraService.delSouarAlquraOne(q_Id,s_Id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
      if(this.resp.error){
       this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
         dismissible: true, 
         timeout: 5000,
         type: 'danger' });
     }
       this._flashMsg.showFlashMessage(
        { messages: ['تم حذف سور القاريء'],
       dismissible: true, 
       timeout: 5000,
       type: 'success' });
       this.getSouarAlqura();}
     );}
}