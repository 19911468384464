import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import  { map } from 'rxjs/operators';
import  * as AppUtils  from '../common/app.util';

@Injectable({
  providedIn: 'root'
})
export class AlquraService {

  constructor(private _http:HttpClient) { }
  private createAuthHeader(headers:HttpHeaders){
    var token=localStorage.getItem(AppUtils.AUTH_TOKEN);
    headers = headers.set('Authorization',`Bearer ${token} `);
    return headers;
  }
  public getCurrentUserID(){
    var user= JSON.parse(localStorage.getItem(AppUtils.USER_INFO));
    return user.U_ID;
  }
  public createAlqary(Alqary){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.post("alqura/add",Alqary,header)
    .pipe(map(resp=>resp));  
  }
  public getAlqura(){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("alqura/Q_Order",header)
    .pipe(map(resp=>resp)); 
  }
  public getAlqaryById(q_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.get("alqura/"+q_Id,header)
    .pipe(map(resp=>resp)); 
  }

  public editAlqary(q_Id,Alqary){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.put("alqura/edit/"+q_Id,Alqary,header)
    .pipe(map(resp=>resp));  
  }
  public delAlqary(q_Id){
    const headers=new HttpHeaders();
    const header={headers:this.createAuthHeader(headers)};
    return this._http.delete("alqura/"+q_Id,header)
    .pipe(map(resp=>resp)); 
  }
}
