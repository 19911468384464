import { Component, OnInit ,Inject} from '@angular/core';
import { Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { UsersService } from '../../services/users.service';
import{NgxSpinnerService}from'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  name:string;
  password:string;
  resp:any=[];

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _usersService:UsersService,
    private _flashMsg:NgFlashMessageService , 
    private _router:Router) { }

  ngOnInit(): void {
  }
  onLogin(){
    if(!this.name ||!this.password){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواًُ عليك كتابة اسم المستخدم وكلمة المرور"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.name ){
        this._flashMsg.showFlashMessage(
        { messages: ["عفواًُ عليك كتابة اسم المستخدم"],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
        return false;
        }if(!this.password){
          this._flashMsg.showFlashMessage(
          { messages: ["عفواًُ عليك كتابة كلمة المرور"],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
          return false;
          }this._spinner.show();
      const user={
        U_Name:this.name,
        U_Password:this.password}
        this._usersService.login(user).subscribe(
          (resp:{})=>{this.resp=resp
            this._spinner.hide();
          if(!this.resp.success){
            this._flashMsg.showFlashMessage(
              { messages: [this.resp.message],
              dismissible: true, 
              timeout: 5000,
              type: 'danger' });
          }
           this._flashMsg.showFlashMessage(
            { messages: [this.resp.message],
            dismissible: true, 
            timeout: 5000,
            type: 'success' });
            this._usersService.saveUserData(this.resp.token,this.resp.user);
            return this._router.navigate(['/']); 
          }
        );
  }

}
