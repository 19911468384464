import { Component, OnInit ,Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { SouarAlquraService } from '../../services/souar-alqura.service';
import { AlquraService } from '../../services/alqura.service';
import { NgxSpinnerService } from "ngx-spinner"; 
import { FileUploader,FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-souar-alqura',
  templateUrl: './souar-alqura.component.html',
  styleUrls: ['./souar-alqura.component.css']
})
export class SouarAlquraComponent implements OnInit {
  term:string;
  config:any;
  souarAlqura:any;
  Alqura:any;
  resp:any=[];
  
  public uploader: FileUploader = new FileUploader({
    url:environment.uploadUrl,
    itemAlias: 'alqura_file'
  });

  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alquraService:AlquraService,
  private _souarAlquraService:SouarAlquraService,
  private _route: ActivatedRoute, 
 private _router:Router,
 private _flashMsg:NgFlashMessageService) { }

 
ngOnInit() {
 this.getSouarAlqura();
 this.config = {
   currentPage: 1,
   itemsPerPage: 6,
   totalItems:0
   };    
   this._route.queryParams.subscribe(
     params => this.config.currentPage= params['page']?params['page']:1 );   

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onErrorItem = (item, response, status, headers) => this.onUploaderErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onUploaderSuccessItem(item, response, status, headers);
}

onUploaderSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  let data = JSON.parse(response); 
  alert(data.message)
}

onUploaderErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  let error = JSON.parse(response); 
  alert(error.message);
}
pageChange(newPage: number) {
   this._router.navigate(['souarAlqura'], { queryParams: { page: newPage } });
 }
 getSouarAlqura(){
   this._spinner.show();
   this._alquraService.getAlqura().subscribe(
   (resp:{})=>{this.resp=resp
       this._spinner.hide();
     if(!this.resp.error&& this.resp.count>0){
       this.Alqura=this.resp.alqura;
   }});
}
AddSouarAlqary(q_Id){
  window.localStorage.removeItem("SQSAddSQary_Id");
  window.localStorage.setItem("SQSAddSQary_Id", q_Id);
  this._router.navigate(['souarAlqura-add']);
}
ShowSouarAlqary(q_Id){
 window.localStorage.removeItem("SQShowSQary_Id");
     window.localStorage.setItem("SQShowSQary_Id", q_Id);
     this._router.navigate(['souarAlqura-show']);
}
delSouarAlquraByQ(q_Id){
  this._spinner.show();
 this._souarAlquraService.delSouarAlquraByQ(q_Id).subscribe(
   (resp:{})=>{this.resp=resp
    this._spinner.hide();
     if(this.resp.error){
       this._flashMsg.showFlashMessage(
        { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
         dismissible: true, 
         timeout: 5000,
         type: 'danger' });
     }
       this._flashMsg.showFlashMessage(
        { messages: ['تم حذف سور القاريء'],
       dismissible: true, 
       timeout: 5000,
       type: 'success' });
       this.getSouarAlqura();}
     );}
}
