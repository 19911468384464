import { Component, OnInit ,Inject} from '@angular/core';
import {  Router } from '@angular/router';
import {NgFlashMessageService } from 'ng-flash-messages';
import { AlsouarService } from '../../services/alsouar.service';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-alsouar-add',
  templateUrl: './alsouar-add.component.html',
  styleUrls: ['./alsouar-add.component.css']
})
export class AlsouarAddComponent implements OnInit {

  ar_name:string;
  en_name:string;
  a_count:number;
  m_order:number;
  resp:any=[];
  constructor(
    @Inject(NgxSpinnerService)  private _spinner: NgxSpinnerService,
    private _alsouarService:AlsouarService,
   private _router:Router,
   private _flashMsg:NgFlashMessageService) { }

  ngOnInit(): void {
  }
  onCreateSoura(){
    
    if(!this.ar_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالعربية مطلوب"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }
      if(!this.en_name ){
      this._flashMsg.showFlashMessage(
      { messages: ["عفواً الاسم بالإنجليزية مطلوبة"],
      dismissible: true, 
      timeout: 5000,
      type: 'danger' });
      return false;
      }      if(!this.a_count ){
        this._flashMsg.showFlashMessage(
        { messages: ["عفواً عدد الآيات مطلوبة"],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
        return false;
        }
        if(!this.m_order ){
        this._flashMsg.showFlashMessage(
        { messages: ["عفواً ترتيب مطلوبة"],
        dismissible: true, 
        timeout: 5000,
        type: 'danger' });
        return false;
        }this._spinner.show();
    const soura={
      S_Arabic_Name:this.ar_name,
      S_English_Name:this.en_name,
      S_Ayat_Count:this.a_count,
      Moshaf_Order:this.m_order,
      Inserted_By :this._alsouarService.getCurrentUserID()}
    this._alsouarService.createAlsouara(soura).subscribe(
      (resp:{})=>{this.resp=resp
        this._spinner.hide();
      if(this.resp.error|| this.resp.num===0){
        this._flashMsg.showFlashMessage(
          { messages: ['عفواً حدث خطأ , الرجاء المحاولة مرة أخرى'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(this.resp.error || this.resp.num===2){
        this._flashMsg.showFlashMessage(
          { messages: ['هذا الاسم موجود من قبل'],
          dismissible: true, 
          timeout: 5000,
          type: 'danger' });
      }
      if(!this.resp.error || this.resp.num===1){
        this._flashMsg.showFlashMessage(
          { messages: ['تم إضافة بيانات السورة'],
        dismissible: true, 
        timeout: 5000,
        type: 'success' });
      return this._router.navigate(['/alsouar']); }}
      );
  }
}
